import * as fwx from "../../../../framework/forms/form-export";
import {
    GeschaeftspartnerEditUtils
} from "./geschaeftspartner-edit-utils";
import {
    StatusService
} from "../../../services/status-service";
import {
    AuftragService
} from "../../../services/auftrag-service";

@fwx.autoinject
export class GeschaeftspartnerEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_GeschaeftspartnerEditUtils: GeschaeftspartnerEditUtils,
        private $f_StatusService: StatusService,
        private $f_AuftragService: AuftragService) {
        super(element, formBaseImport);
        this.id = "geschaeftspartner-edit";
        this.title = "geschaeftspartner-edit.geschaeftspartner-edit_caption";
        this.addModel({
            "id": "$m_Geschaeftspartner",
            "webApiAction": "ERP/Stammdaten/Geschaeftspartner",
            "webApiExpand": {
                'Geschaeftspartnerart': {
                    'columns': [
                        'Id',
                        'IsKunde',
                        'IsInteressent',
                        'IsLieferant',
                        'IsRechnungsadresse'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "custom": {
                'copyFunc': '$f_GeschaeftspartnerEditUtils.copyGeschaeftspartner'
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonRel",
            "webApiAction": "ERP/Stammdaten/Person",
            "webApiExpand": {
                'Anrede': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'gpPersonGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_PersonEdit",
            "webApiAction": "ERP/Stammdaten/Person",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BankverbindungRel",
            "webApiAction": "ERP/Stammdaten/Bankverbindung",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_BankverbindungEdit",
            "webApiAction": "ERP/Stammdaten/Bankverbindung",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerCopy",
            "filters": []
        });
        this.addModel({
            "id": "$m_GeschaeftspartnerEdit",
            "webApiAction": "ERP/Stammdaten/Geschaeftspartner",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_Brief",
            "webApiAction": "ERP/Stammdaten/Brief",
            "webApiExpand": {
                'Status': null,
                'Projekt': null,
                'Sachbearbeiter': null,
                'Firma': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_kommunikationBrief'
                ]
            },
            "filters": [{
                "webApiCustomKey": "IdGeschaeftspartner",
                "webApiCustomValue": "models.data.$m_Geschaeftspartner.Id"
            }]
        });
        this.addModel({
            "id": "$m_MailImportItem",
            "webApiAction": "ERP/Stammdaten/MailImportItem",
            "webApiExpand": {
                'MailImportDefinition': null,
                Status: null,
                Geschaeftspartner: {
                    expand: {
                        Adresse: null
                    }
                },
                Person: null
            },
            "webApiWhere": ["IdCboGeschaeftspartner", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_kommunikationMail'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Projekt",
            "webApiAction": "ERP/Stammdaten/Projekt",
            "webApiExpand": {
                'Status': null,
                'Projektart': null,
                'Sachbearbeiter': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewProjektGrid'
                ]
            },
            "filters": [{
                "webApiCustomKey": "IdGeschaeftspartner",
                "webApiCustomValue": "models.data.$m_Geschaeftspartner.Id"
            }]
        });
        this.addModel({
            "id": "$m_BewAngebot",
            "webApiAction": "ERP/Verkauf/Angebot",
            "webApiExpand": {
                'Angebotsart': null,
                'Projekt': null,
                'Status': null,
                'Waehrung': null
            },
            "webApiWhere": ["Kunde.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewAngebotGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewAuftrag",
            "webApiAction": "ERP/Verkauf/Auftrag",
            "webApiExpand": {
                'Auftagsart': null,
                'Status': null,
                'Projekt': null,
                'Waehrung': null
            },
            "webApiWhere": ["Kunde.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewAuftragGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewLieferschein",
            "webApiAction": "ERP/Verkauf/Lieferschein",
            "webApiExpand": {
                'Waehrung': null,
                'Projekt': null,
                'Status': null
            },
            "webApiWhere": ["Kunde.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewLieferscheinGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewFaktura",
            "webApiAction": "ERP/Verkauf/Faktura",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null
            },
            "webApiWhere": ["Rechnungsort.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewFakturaGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewMahnung",
            "webApiAction": "ERP/Verkauf/Mahnung",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null
            },
            "webApiWhere": ["Rechnungsort.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewMahnungGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewKundenreklamation",
            "webApiAction": "ERP/Verkauf/Kundenreklamation",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null
            },
            "webApiWhere": ["Kunde.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewKundenreklamationGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewBestellung",
            "webApiAction": "ERP/Einkauf/Bestellung",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null,
                'Auftrag': {
                    'columns': [
                        'Id',
                        'Nummer'
                    ]
                }
            },
            "webApiWhere": ["Lieferant.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewBestellungGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewWarenuebernahme",
            "webApiAction": "ERP/Einkauf/Warenuebernahme",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null
            },
            "webApiWhere": ["Lieferant.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewWarenuebernahmeGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_BewEingangsrechnung",
            "webApiAction": "ERP/Einkauf/Eingangsrechnung",
            "webApiExpand": {
                'Waehrung': null,
                'Status': null,
                'Projekt': null
            },
            "webApiWhere": ["Lieferant.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "webApiOrderBy": [{
                "columnName": "Id",
                "sortOrder": 1
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewEingangsrechnungGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Geschaeftspartnerart",
            "webApiAction": "ERP/Stammdaten/Geschaeftspartnerart",
            "webApiColumns": ["Id", "IsKunde", "IsLieferant"],
            "optimisticLockingEnabled": true,
            "key": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerart",
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_KassaBeleg",
            "webApiAction": "ERP/Kassa/KassaBeleg",
            "webApiExpand": {
                'Zahlungsart': null,
                'Waehrung': null,
                'Status': null,
                'Kassa': null,
                'Firma': null
            },
            "webApiWhere": ["Kunde.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "custom": {
                'addTagToDataGrids': [
                    'r_bewKassaBelegGrid'
                ]
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_Zahlung",
            "webApiAction": "ERP/Verkauf/Zahlung",
            "webApiExpand": {
                'Zahlungsart': null,
                'Waehrung': null,
                'Eingangsrechnung': null,
                'Faktura': null,
                'KassaBeleg': null
            },
            "webApiWhere": ["Geschaeftspartner.Id", {
                "isBound": true,
                "expression": "models.data.$m_Geschaeftspartner.Id"
            }],
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftspartnerEditUtils.showGeschaeftspartnerartAendernCommand",
                "bindToFQ": "functions.$f_GeschaeftspartnerEditUtils.showGeschaeftspartnerartAendernCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftspartnerEditUtils.gpAngebotErstellenCommand",
                "bindToFQ": "functions.$f_GeschaeftspartnerEditUtils.gpAngebotErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_GeschaeftspartnerEditUtils.gpAuftragErstellenCommand",
                "bindToFQ": "functions.$f_GeschaeftspartnerEditUtils.gpAuftragErstellenCommand"
            }
        });
        this.addEditPopup({
            "idContent": "personEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_PersonEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_PersonEdit.Id"
                }
            }],
            "id": "personEditPopup",
            "options": {
                "optionsName": "personEditPopupOptions",
                "optionsNameFQ": "personEditPopupOptions"
            },
            "commands": []
        });
        this.addEditPopup({
            "idContent": "bankverbindungEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_BankverbindungEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_BankverbindungEdit.Id"
                }
            }],
            "id": "bankverbindungEditPopup",
            "options": {
                "optionsName": "bankverbindungEditPopupOptions",
                "optionsNameFQ": "bankverbindungEditPopupOptions"
            },
            "commands": []
        });
        this.addFunction("$f_GeschaeftspartnerEditUtils", $f_GeschaeftspartnerEditUtils, "functions.$f_GeschaeftspartnerEditUtils");
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_AuftragService", $f_AuftragService, "functions.$f_AuftragService");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "geschaeftspartner-edit.r_nummer_caption",
            "isReadOnlyExpression": "models.data.$m_Geschaeftspartner.Id > 0",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Nummer"
            },
            "validationRules": [],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "geschaeftspartnerart",
            "filters": [],
            "caption": "geschaeftspartner-edit.r_art_caption",
            "isReadOnlyExpression": "models.data.$m_Geschaeftspartner.Id",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "IdGeschaeftspartnerart",
                "bindToFQ": "models.data.$m_Geschaeftspartner.IdGeschaeftspartnerart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_art",
            "options": {
                "optionsName": "r_artOptions",
                "optionsNameFQ": "r_artOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "geschaeftspartner-edit.r_isprivatperson_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "IsPrivatperson",
                "bindToFQ": "models.data.$m_Geschaeftspartner.IsPrivatperson"
            },
            "validationRules": [],
            "id": "r_isPrivatperson",
            "options": {
                "optionsName": "r_isPrivatpersonOptions",
                "optionsNameFQ": "r_isPrivatpersonOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "geschaeftspartner-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Geschaeftspartner.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "anrede",
            "filters": [],
            "caption": "geschaeftspartner-edit.r_persanrede_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "_IdAnrede",
                "bindToFQ": "models.data.$m_Geschaeftspartner._IdAnrede"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_persAnrede",
            "options": {
                "optionsName": "r_persAnredeOptions",
                "optionsNameFQ": "r_persAnredeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "geschaeftspartner-edit.r_perstitelvorne_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "_TitelVorne",
                "bindToFQ": "models.data.$m_Geschaeftspartner._TitelVorne"
            },
            "validationRules": [],
            "id": "r_persTitelVorne",
            "options": {
                "optionsName": "r_persTitelVorneOptions",
                "optionsNameFQ": "r_persTitelVorneOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "geschaeftspartner-edit.r_persvorname_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "_Vorname",
                "bindToFQ": "models.data.$m_Geschaeftspartner._Vorname"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_persVorname",
            "options": {
                "optionsName": "r_persVornameOptions",
                "optionsNameFQ": "r_persVornameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "geschaeftspartner-edit.r_persnachname_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "_Nachname",
                "bindToFQ": "models.data.$m_Geschaeftspartner._Nachname"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_persNachname",
            "options": {
                "optionsName": "r_persNachnameOptions",
                "optionsNameFQ": "r_persNachnameOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "geschaeftspartner-edit.r_perstitelhinten_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "_TitelHinten",
                "bindToFQ": "models.data.$m_Geschaeftspartner._TitelHinten"
            },
            "validationRules": [],
            "id": "r_persTitelHinten",
            "options": {
                "optionsName": "r_persTitelHintenOptions",
                "optionsNameFQ": "r_persTitelHintenOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-edit.r_email_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Email",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Email"
            },
            "validationRules": [{
                "item": {
                    "type": "email",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 150
                    }]
                }
            }],
            "id": "r_email",
            "options": {
                "optionsName": "r_emailOptions",
                "optionsNameFQ": "r_emailOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 250,
            "caption": "geschaeftspartner-edit.r_homepage_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Homepage",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Homepage"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 250
                    }]
                }
            }],
            "id": "r_homepage",
            "options": {
                "optionsName": "r_homepageOptions",
                "optionsNameFQ": "r_homepageOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-edit.r_telefon_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Telefon",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Telefon"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_telefon",
            "options": {
                "optionsName": "r_telefonOptions",
                "optionsNameFQ": "r_telefonOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "geschaeftspartner-edit.r_fax_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Fax",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Fax"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_fax",
            "options": {
                "optionsName": "r_faxOptions",
                "optionsNameFQ": "r_faxOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "geschaeftspartner-edit.r_uidnummer_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "UIDNummer",
                "bindToFQ": "models.data.$m_Geschaeftspartner.UIDNummer"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_uidNummer",
            "options": {
                "optionsName": "r_uidNummerOptions",
                "optionsNameFQ": "r_uidNummerOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabPersonen",
                "caption": "geschaeftspartner-edit.r_tabpersonen_caption"
            }, {
                "id": "r_tabBankverbindung",
                "caption": "geschaeftspartner-edit.r_tabbankverbindung_caption"
            }, {
                "id": "r_tabKondition",
                "caption": "geschaeftspartner-edit.r_tabkondition_caption"
            }, {
                "id": "r_tabSonstiges",
                "caption": "geschaeftspartner-edit.r_tabsonstiges_caption"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_gpPersAnrede",
                "caption": "geschaeftspartner-edit.r_gppersanrede_caption",
                "bindTo": "Anrede.Bezeichnung",
                "width": "80px"
            }, {
                "id": "r_gpPersVorname",
                "caption": "geschaeftspartner-edit.r_gppersvorname_caption",
                "bindTo": "Vorname",
                "minWidth": 100
            }, {
                "id": "r_gpPersNachname",
                "caption": "geschaeftspartner-edit.r_gppersnachname_caption",
                "bindTo": "Nachname",
                "minWidth": 100
            }, {
                "id": "r_gpPersEmailPerson",
                "caption": "geschaeftspartner-edit.r_gppersemailperson_caption",
                "bindTo": "Email",
                "minWidth": 100
            }, {
                "id": "r_gpPersTelefonPerson",
                "caption": "geschaeftspartner-edit.r_gpperstelefonperson_caption",
                "bindTo": "Telefon",
                "width": "160px"
            }, {
                "id": "r_gpPersMobiltelefon",
                "caption": "geschaeftspartner-edit.r_gppersmobiltelefon_caption",
                "bindTo": "Mobiltelefon",
                "width": "160px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "gpPersonGridToolbarOptions",
                "optionsNameFQ": "gpPersonGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.gppersongrid_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_PersonRel",
                "bindTo": "IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_PersonRel.IdGeschaeftspartner"
            },
            "dataModel": "$m_Geschaeftspartner",
            "editDataContext": "$m_PersonEdit",
            "idEditPopup": "personEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "gpPersonGrid",
            "options": {
                "optionsName": "gpPersonGridOptions",
                "optionsNameFQ": "gpPersonGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_gpBavIban",
                "caption": "geschaeftspartner-edit.r_gpbaviban_caption",
                "bindTo": "IBAN",
                "width": "120px"
            }, {
                "id": "r_gpBavBic",
                "caption": "geschaeftspartner-edit.r_gpbavbic_caption",
                "bindTo": "BIC",
                "width": "120px"
            }, {
                "id": "r_gpBavGueltigVon",
                "caption": "geschaeftspartner-edit.r_gpbavgueltigvon_caption",
                "bindTo": "GueltigVon",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_gpBavGueltigBis",
                "caption": "geschaeftspartner-edit.r_gpbavgueltigbis_caption",
                "bindTo": "GueltigBis",
                "width": "90px",
                "format": "d"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "gpBankverbindungGridToolbarOptions",
                "optionsNameFQ": "gpBankverbindungGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.gpbankverbindunggrid_caption",
            "binding": {
                "dataContext": "$m_Geschaeftspartner"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_BankverbindungRel",
                "bindTo": "IdGeschaeftspartner",
                "bindToFQ": "models.data.$m_BankverbindungRel.IdGeschaeftspartner"
            },
            "dataModel": "$m_Geschaeftspartner",
            "editDataContext": "$m_BankverbindungEdit",
            "idEditPopup": "bankverbindungEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "gpBankverbindungGrid",
            "options": {
                "optionsName": "gpBankverbindungGridOptions",
                "optionsNameFQ": "gpBankverbindungGridOptions"
            }
        });
        this.addNestedForm("konditionListNested", [{
            "to": "$v_idGeschaeftspartner",
            "binding": {
                "dataContext": "$m_Geschaeftspartner",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Geschaeftspartner.Id",
                "propertyPrefix": null
            }
        }]);
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabKommunikationMail",
                "caption": "geschaeftspartner-edit.r_tabkommunikationmail_caption",
                "if": "functions.$f_GeschaeftspartnerEditUtils.startupService.startupInfo.Lizenz.HasMailImport"
            }, {
                "id": "r_tabKommunikationBrief",
                "caption": "geschaeftspartner-edit.r_tabkommunikationbrief_caption"
            }, {
                "id": "r_tabDashboard",
                "caption": "geschaeftspartner-edit.r_tabdashboard_caption",
                "if": "models.data.$m_Geschaeftspartner.Id"
            }, {
                "id": "r_tabProjekte",
                "caption": "geschaeftspartner-edit.r_tabprojekte_caption"
            }, {
                "id": "r_tabBewAngebot",
                "caption": "geschaeftspartner-edit.r_tabbewangebot_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsKunde || models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsInteressent"
            }, {
                "id": "r_tabBewAuftrag",
                "caption": "geschaeftspartner-edit.r_tabbewauftrag_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsKunde"
            }, {
                "id": "r_tabBewLieferschein",
                "caption": "geschaeftspartner-edit.r_tabbewlieferschein_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsKunde"
            }, {
                "id": "r_tabBewFaktura",
                "caption": "geschaeftspartner-edit.r_tabbewfaktura_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsKunde || models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsRechnungsadresse"
            }, {
                "id": "r_tabBewKundenreklamation",
                "caption": "geschaeftspartner-edit.r_tabbewkundenreklamation_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsKunde"
            }, {
                "id": "r_tabBewBestellung",
                "caption": "geschaeftspartner-edit.r_tabbewbestellung_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsLieferant"
            }, {
                "id": "r_tabBewWarenuebernahme",
                "caption": "geschaeftspartner-edit.r_tabbewwarenuebernahme_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsLieferant"
            }, {
                "id": "r_tabBewEingangsrechnung",
                "caption": "geschaeftspartner-edit.r_tabbeweingangsrechnung_caption",
                "if": "models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsLieferant"
            }, {
                "id": "r_tabBewKassabelege",
                "caption": "geschaeftspartner-edit.r_tabbewkassabelege_caption",
                "if": "functions.$f_GeschaeftspartnerEditUtils.startupService.startupInfo.Lizenz.HasKassa"
            }, {
                "id": "r_tabBewZahlungen",
                "caption": "geschaeftspartner-edit.r_tabbewzahlungen_caption"
            }, {
                "id": "r_tabBewMahnung",
                "caption": "geschaeftspartner-edit.r_tabbewmahnung_caption",
                "if": "(models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsKunde || models.data.$m_Geschaeftspartner.Geschaeftspartnerart.IsRechnungsadresse ) && functions.$f_GeschaeftspartnerEditUtils.hasMahnung"
            }],
            "id": "r_tabBewegung",
            "options": {
                "optionsName": "r_tabBewegungOptions",
                "optionsNameFQ": "r_tabBewegungOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_maItMailImportDefinition",
                "caption": "geschaeftspartner-edit.r_maitmailimportdefinition_caption",
                "bindTo": "MailImportDefinition.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_maItDatum",
                "caption": "geschaeftspartner-edit.r_maitdatum_caption",
                "bindTo": "Datum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_maItBetreff",
                "caption": "geschaeftspartner-edit.r_maitbetreff_caption",
                "bindTo": "Betreff",
                "minWidth": 100
            }, {
                "id": "r_maItPerson",
                "caption": "geschaeftspartner-edit.r_maitperson_caption",
                "bindTo": "Person.Name",
                "width": "140px"
            }, {
                "id": "r_maItStatus",
                "caption": "geschaeftspartner-edit.r_maitstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_kommunikationMailToolbarOptions",
                "optionsNameFQ": "r_kommunikationMailToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_MailImportItem"
            },
            "dataModel": "$m_MailImportItem",
            "editUrl": "ERP/Stammdaten/MailImportItem",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kommunikationMail",
            "options": {
                "optionsName": "r_kommunikationMailOptions",
                "optionsNameFQ": "r_kommunikationMailOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_briefFirma",
                "caption": "geschaeftspartner-edit.r_brieffirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_briefSachbearbeiter",
                "caption": "geschaeftspartner-edit.r_briefsachbearbeiter_caption",
                "bindTo": "Sachbearbeiter.Name",
                "width": "140px"
            }, {
                "id": "r_briefProjekt",
                "caption": "geschaeftspartner-edit.r_briefprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "width": "150px"
            }, {
                "id": "r_briefDatum",
                "caption": "geschaeftspartner-edit.r_briefdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_briefEmpfaenger",
                "caption": "geschaeftspartner-edit.r_briefempfaenger_caption",
                "bindTo": "EmpfaengerDisplayText",
                "minWidth": 100
            }, {
                "id": "r_briefBetreff",
                "caption": "geschaeftspartner-edit.r_briefbetreff_caption",
                "bindTo": "Betreff",
                "width": "100px"
            }, {
                "id": "r_briefText",
                "caption": "geschaeftspartner-edit.r_brieftext_caption",
                "bindTo": "Text",
                "minWidth": 100
            }, {
                "id": "r_briefAnzAnhaenge",
                "caption": "geschaeftspartner-edit.r_briefanzanhaenge_caption",
                "bindTo": "AnzAnhaenge",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_briefStatus",
                "caption": "geschaeftspartner-edit.r_briefstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_kommunikationBriefToolbarOptions",
                "optionsNameFQ": "r_kommunikationBriefToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Brief"
            },
            "dataModel": "$m_Brief",
            "editUrl": "ERP/Stammdaten/Brief",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kommunikationBrief",
            "options": {
                "optionsName": "r_kommunikationBriefOptions",
                "optionsNameFQ": "r_kommunikationBriefOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_projeNummer",
                "caption": "geschaeftspartner-edit.r_projenummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_projeProjektart",
                "caption": "geschaeftspartner-edit.r_projeprojektart_caption",
                "bindTo": "Projektart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_projeBezeichnung",
                "caption": "geschaeftspartner-edit.r_projebezeichnung_caption",
                "bindTo": "Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_projeSachbearbeiter",
                "caption": "geschaeftspartner-edit.r_projesachbearbeiter_caption",
                "bindTo": "Sachbearbeiter.Name",
                "width": "140px"
            }, {
                "id": "r_projeStatus",
                "caption": "geschaeftspartner-edit.r_projestatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewProjektGridToolbarOptions",
                "optionsNameFQ": "r_bewProjektGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_Projekt"
            },
            "dataModel": "$m_Projekt",
            "editUrl": "ERP/Stammdaten/Projekt",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewProjektGrid",
            "options": {
                "optionsName": "r_bewProjektGridOptions",
                "optionsNameFQ": "r_bewProjektGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_angNummer",
                "caption": "geschaeftspartner-edit.r_angnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_angDatum",
                "caption": "geschaeftspartner-edit.r_angdatum_caption",
                "bindTo": "Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_angProjekt",
                "caption": "geschaeftspartner-edit.r_angprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_angGueltigBis",
                "caption": "geschaeftspartner-edit.r_anggueltigbis_caption",
                "bindTo": "GueltigBis",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_angWertNetto",
                "caption": "geschaeftspartner-edit.r_angwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_angWaehrung",
                "caption": "geschaeftspartner-edit.r_angwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_angStatus",
                "caption": "geschaeftspartner-edit.r_angstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewAngebotGridToolbarOptions",
                "optionsNameFQ": "r_bewAngebotGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewangebotgrid_caption",
            "binding": {
                "dataContext": "$m_BewAngebot"
            },
            "dataModel": "$m_BewAngebot",
            "editUrl": "ERP/Verkauf/Angebot",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewAngebotGrid",
            "options": {
                "optionsName": "r_bewAngebotGridOptions",
                "optionsNameFQ": "r_bewAngebotGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_aufNummer",
                "caption": "geschaeftspartner-edit.r_aufnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_aufDatum",
                "caption": "geschaeftspartner-edit.r_aufdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_aufProjekt",
                "caption": "geschaeftspartner-edit.r_aufprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_aufLieferdatum",
                "caption": "geschaeftspartner-edit.r_auflieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_AuftragService.onLieferdatumCellPrepared"
            }, {
                "id": "r_aufReservierungsgrad",
                "caption": "geschaeftspartner-edit.r_aufreservierungsgrad_caption",
                "bindTo": "Reservierungsgrad",
                "width": "80px",
                "format": "p0",
                "onCellPrepared": "functions.$f_GeschaeftspartnerEditUtils.onReservierungsgradCellPrepared"
            }, {
                "id": "r_aufWertNetto",
                "caption": "geschaeftspartner-edit.r_aufwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_aufWaehrung",
                "caption": "geschaeftspartner-edit.r_aufwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_aufStatus",
                "caption": "geschaeftspartner-edit.r_aufstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewAuftragGridToolbarOptions",
                "optionsNameFQ": "r_bewAuftragGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewauftraggrid_caption",
            "binding": {
                "dataContext": "$m_BewAuftrag"
            },
            "dataModel": "$m_BewAuftrag",
            "editUrl": "ERP/Verkauf/Auftrag",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewAuftragGrid",
            "options": {
                "optionsName": "r_bewAuftragGridOptions",
                "optionsNameFQ": "r_bewAuftragGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_liefsNummer",
                "caption": "geschaeftspartner-edit.r_liefsnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_liefsDatum",
                "caption": "geschaeftspartner-edit.r_liefsdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_liefsProjekt",
                "caption": "geschaeftspartner-edit.r_liefsprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_liefsWertNetto",
                "caption": "geschaeftspartner-edit.r_liefswertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_liefsWaehrung",
                "caption": "geschaeftspartner-edit.r_liefswaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_liefsStatus",
                "caption": "geschaeftspartner-edit.r_liefsstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewLieferscheinGridToolbarOptions",
                "optionsNameFQ": "r_bewLieferscheinGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewlieferscheingrid_caption",
            "binding": {
                "dataContext": "$m_BewLieferschein"
            },
            "dataModel": "$m_BewLieferschein",
            "editUrl": "ERP/Verkauf/Lieferschein",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewLieferscheinGrid",
            "options": {
                "optionsName": "r_bewLieferscheinGridOptions",
                "optionsNameFQ": "r_bewLieferscheinGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_fakNummer",
                "caption": "geschaeftspartner-edit.r_faknummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_fakDatum",
                "caption": "geschaeftspartner-edit.r_fakdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_fakProjekt",
                "caption": "geschaeftspartner-edit.r_fakprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_fakWertNetto",
                "caption": "geschaeftspartner-edit.r_fakwertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_fakWaehrung",
                "caption": "geschaeftspartner-edit.r_fakwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_fakAbschlagsfaktura",
                "caption": "geschaeftspartner-edit.r_fakabschlagsfaktura_caption",
                "bindTo": "IsAbschlagsfaktura",
                "width": "120px"
            }, {
                "id": "r_fakZahlungFaellig",
                "caption": "geschaeftspartner-edit.r_fakzahlungfaellig_caption",
                "bindTo": "ZahlungFaelligkeit",
                "width": "90px",
                "format": "d",
                "onCellPrepared": "functions.$f_GeschaeftspartnerEditUtils.onZahlungFaelligCellPrepared"
            }, {
                "id": "r_fakMahnstufe",
                "caption": "geschaeftspartner-edit.r_fakmahnstufe_caption",
                "bindTo": "Mahnstufe",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_fakStatus",
                "caption": "geschaeftspartner-edit.r_fakstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewFakturaGridToolbarOptions",
                "optionsNameFQ": "r_bewFakturaGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewfakturagrid_caption",
            "binding": {
                "dataContext": "$m_BewFaktura"
            },
            "dataModel": "$m_BewFaktura",
            "editUrl": "ERP/Verkauf/Faktura",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewFakturaGrid",
            "options": {
                "optionsName": "r_bewFakturaGridOptions",
                "optionsNameFQ": "r_bewFakturaGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_reklNummer",
                "caption": "geschaeftspartner-edit.r_reklnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_reklDatum",
                "caption": "geschaeftspartner-edit.r_rekldatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_reklProjekt",
                "caption": "geschaeftspartner-edit.r_reklprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_reklWaehrung",
                "caption": "geschaeftspartner-edit.r_reklwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_reklStatus",
                "caption": "geschaeftspartner-edit.r_reklstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewKundenreklamationGridToolbarOptions",
                "optionsNameFQ": "r_bewKundenreklamationGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewkundenreklamationgrid_caption",
            "binding": {
                "dataContext": "$m_BewKundenreklamation"
            },
            "dataModel": "$m_BewKundenreklamation",
            "editUrl": "ERP/Verkauf/Kundenreklamation",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewKundenreklamationGrid",
            "options": {
                "optionsName": "r_bewKundenreklamationGridOptions",
                "optionsNameFQ": "r_bewKundenreklamationGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_besNummer",
                "caption": "geschaeftspartner-edit.r_besnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_besDatum",
                "caption": "geschaeftspartner-edit.r_besdatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_besProjekt",
                "caption": "geschaeftspartner-edit.r_besprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_besAuftragNr",
                "caption": "geschaeftspartner-edit.r_besauftragnr_caption",
                "bindTo": "Auftrag.Nummer",
                "width": "80px"
            }, {
                "id": "r_besLieferdatum",
                "caption": "geschaeftspartner-edit.r_beslieferdatum_caption",
                "bindTo": "Lieferdatum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_besWertNetto",
                "caption": "geschaeftspartner-edit.r_beswertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_besWaehrung",
                "caption": "geschaeftspartner-edit.r_beswaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_besStatus",
                "caption": "geschaeftspartner-edit.r_besstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewBestellungGridToolbarOptions",
                "optionsNameFQ": "r_bewBestellungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BewBestellung"
            },
            "dataModel": "$m_BewBestellung",
            "editUrl": "ERP/Einkauf/Bestellung",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewBestellungGrid",
            "options": {
                "optionsName": "r_bewBestellungGridOptions",
                "optionsNameFQ": "r_bewBestellungGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_waNummer",
                "caption": "geschaeftspartner-edit.r_wanummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_waDatum",
                "caption": "geschaeftspartner-edit.r_wadatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_waProjekt",
                "caption": "geschaeftspartner-edit.r_waprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_waWertNetto",
                "caption": "geschaeftspartner-edit.r_wawertnetto_caption",
                "bindTo": "WertNetto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_waWaehrung",
                "caption": "geschaeftspartner-edit.r_wawaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_waStatus",
                "caption": "geschaeftspartner-edit.r_wastatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewWarenuebernahmeGridToolbarOptions",
                "optionsNameFQ": "r_bewWarenuebernahmeGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BewWarenuebernahme"
            },
            "dataModel": "$m_BewWarenuebernahme",
            "editUrl": "ERP/Einkauf/Warenuebernahme",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewWarenuebernahmeGrid",
            "options": {
                "optionsName": "r_bewWarenuebernahmeGridOptions",
                "optionsNameFQ": "r_bewWarenuebernahmeGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_einNummer",
                "caption": "geschaeftspartner-edit.r_einnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_einDatum",
                "caption": "geschaeftspartner-edit.r_eindatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_einProjekt",
                "caption": "geschaeftspartner-edit.r_einprojekt_caption",
                "bindTo": "Projekt.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_einWaehrung",
                "caption": "geschaeftspartner-edit.r_einwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_einStatus",
                "caption": "geschaeftspartner-edit.r_einstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewEingangsrechnungGridToolbarOptions",
                "optionsNameFQ": "r_bewEingangsrechnungGridToolbarOptions"
            },
            "binding": {
                "dataContext": "$m_BewEingangsrechnung"
            },
            "dataModel": "$m_BewEingangsrechnung",
            "editUrl": "ERP/Einkauf/Eingangsrechnung",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewEingangsrechnungGrid",
            "options": {
                "optionsName": "r_bewEingangsrechnungGridOptions",
                "optionsNameFQ": "r_bewEingangsrechnungGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_kassaBelKassa",
                "caption": "geschaeftspartner-edit.r_kassabelkassa_caption",
                "bindTo": "Kassa.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_kassaBelFirma",
                "caption": "geschaeftspartner-edit.r_kassabelfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_kassaBelNummer",
                "caption": "geschaeftspartner-edit.r_kassabelnummer_caption",
                "bindTo": "Nummer",
                "width": "80px"
            }, {
                "id": "r_kassaBelDatum",
                "caption": "geschaeftspartner-edit.r_kassabeldatum_caption",
                "bindTo": "Datum",
                "width": "90px",
                "format": "d"
            }, {
                "id": "r_kassaBelTyp",
                "caption": "geschaeftspartner-edit.r_kassabeltyp_caption",
                "bindTo": "Typ",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.KassaBelegTyp"
            }, {
                "id": "r_kassaBelText",
                "caption": "geschaeftspartner-edit.r_kassabeltext_caption",
                "bindTo": "Text",
                "minWidth": 100
            }, {
                "id": "r_kassaBelZahlungsart",
                "caption": "geschaeftspartner-edit.r_kassabelzahlungsart_caption",
                "bindTo": "Zahlungsart.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_kassaBelAufRechnung",
                "caption": "geschaeftspartner-edit.r_kassabelaufrechnung_caption",
                "bindTo": "IsAufRechnung",
                "width": "80px"
            }, {
                "id": "r_kassaBelWaehrung",
                "caption": "geschaeftspartner-edit.r_kassabelwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_kassaBelZahlbetrag",
                "caption": "geschaeftspartner-edit.r_kassabelzahlbetrag_caption",
                "bindTo": "Zahlbetrag",
                "width": "90px",
                "format": "n2"
            }, {
                "id": "r_kassaBelSignaturStatus",
                "caption": "geschaeftspartner-edit.r_kassabelsignaturstatus_caption",
                "bindTo": "SignaturStatus",
                "width": "120px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.KassaBelegSignaturStatus"
            }, {
                "id": "r_kassaBelStatus",
                "caption": "geschaeftspartner-edit.r_kassabelstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewKassaBelegGridToolbarOptions",
                "optionsNameFQ": "r_bewKassaBelegGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewkassabeleggrid_caption",
            "binding": {
                "dataContext": "$m_KassaBeleg"
            },
            "dataModel": "$m_KassaBeleg",
            "editUrl": "ERP/Kassa/KassaBeleg",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewKassaBelegGrid",
            "options": {
                "optionsName": "r_bewKassaBelegGridOptions",
                "optionsNameFQ": "r_bewKassaBelegGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_zahlungKassaBelKassa",
                "caption": "geschaeftspartner-edit.r_zahlungkassabelkassa_caption",
                "bindTo": "Typ",
                "minWidth": 120,
                "enumTypeName": "TIP.ERP.Business.Enumerations.ZahlungTyp"
            }, {
                "id": "r_zahlungKassaBelFirma",
                "caption": "geschaeftspartner-edit.r_zahlungkassabelfirma_caption",
                "bindTo": "Zahlungsart.Bezeichnung",
                "minWidth": 120
            }, {
                "id": "r_zahlungFakturaNr",
                "caption": "geschaeftspartner-edit.r_zahlungfakturanr_caption",
                "bindTo": "Faktura.Nummer",
                "width": "100px"
            }, {
                "id": "r_zahlungFakturaDatum",
                "caption": "geschaeftspartner-edit.r_zahlungfakturadatum_caption",
                "bindTo": "Faktura.Datum",
                "width": "100px",
                "format": "d"
            }, {
                "id": "r_zahlungEingangsrechnungNr",
                "caption": "geschaeftspartner-edit.r_zahlungeingangsrechnungnr_caption",
                "bindTo": "Eingangsrechnung.Nummer",
                "width": "100px"
            }, {
                "id": "r_zahlungEingangsrechnungDatum",
                "caption": "geschaeftspartner-edit.r_zahlungeingangsrechnungdatum_caption",
                "bindTo": "Eingangsrechnung.Datum",
                "width": "100px",
                "format": "d"
            }, {
                "id": "r_zahlungKassabelegNr",
                "caption": "geschaeftspartner-edit.r_zahlungkassabelegnr_caption",
                "bindTo": "KassaBeleg.Nummer",
                "width": "100px"
            }, {
                "id": "r_zahlungKassabelegDatum",
                "caption": "geschaeftspartner-edit.r_zahlungkassabelegdatum_caption",
                "bindTo": "KassaBeleg.Datum",
                "width": "100px",
                "format": "d"
            }, {
                "id": "r_zahlungBelegNr",
                "caption": "geschaeftspartner-edit.r_zahlungbelegnr_caption",
                "bindTo": "BelegNr",
                "width": "90px"
            }, {
                "id": "r_zahlungBelegdatum",
                "caption": "geschaeftspartner-edit.r_zahlungbelegdatum_caption",
                "bindTo": "Belegdatum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_zahlungBetrag",
                "caption": "geschaeftspartner-edit.r_zahlungbetrag_caption",
                "bindTo": "Betrag",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_zahlungSkonto",
                "caption": "geschaeftspartner-edit.r_zahlungskonto_caption",
                "bindTo": "Skonto",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_zahlungWaehrung",
                "caption": "geschaeftspartner-edit.r_zahlungwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "80px"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewZahlungGridToolbarOptions",
                "optionsNameFQ": "r_bewZahlungGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewzahlunggrid_caption",
            "binding": {
                "dataContext": "$m_Zahlung"
            },
            "dataModel": "$m_Zahlung",
            "editUrl": "ERP/Verkauf/Zahlung",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewZahlungGrid",
            "options": {
                "optionsName": "r_bewZahlungGridOptions",
                "optionsNameFQ": "r_bewZahlungGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "showFilterRow": true,
            "columns": [{
                "id": "r_mahnDatum",
                "caption": "geschaeftspartner-edit.r_mahndatum_caption",
                "bindTo": "Datum",
                "width": "80px",
                "format": "d"
            }, {
                "id": "r_mahnBetragOffen",
                "caption": "geschaeftspartner-edit.r_mahnbetragoffen_caption",
                "bindTo": "BetragOffen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnZinsen",
                "caption": "geschaeftspartner-edit.r_mahnzinsen_caption",
                "bindTo": "Zinsen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnMahnspesen",
                "caption": "geschaeftspartner-edit.r_mahnmahnspesen_caption",
                "bindTo": "Mahnspesen",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_mahnMaxMahnstufe",
                "caption": "geschaeftspartner-edit.r_mahnmaxmahnstufe_caption",
                "bindTo": "MaxMahnstufe",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_mahnWaehrung",
                "caption": "geschaeftspartner-edit.r_mahnwaehrung_caption",
                "bindTo": "Waehrung.ISO4217",
                "width": "70px"
            }, {
                "id": "r_mahnStatus",
                "caption": "geschaeftspartner-edit.r_mahnstatus_caption",
                "bindTo": "Status.Bezeichnung",
                "width": "120px",
                "onCellPrepared": "functions.$f_StatusService.onCellPrepared"
            }],
            "optionsToolbar": {
                "optionsName": "r_bewMahnungGridToolbarOptions",
                "optionsNameFQ": "r_bewMahnungGridToolbarOptions"
            },
            "caption": "geschaeftspartner-edit.r_bewmahnunggrid_caption",
            "binding": {
                "dataContext": "$m_BewMahnung"
            },
            "dataModel": "$m_BewMahnung",
            "editUrl": "ERP/Verkauf/Mahnung",
            "height": "620px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_bewMahnungGrid",
            "options": {
                "optionsName": "r_bewMahnungGridOptions",
                "optionsNameFQ": "r_bewMahnungGridOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_copyPopup",
            "options": {
                "optionsName": "r_copyPopupOptions",
                "optionsNameFQ": "r_copyPopupOptions"
            },
            "caption": "geschaeftspartner-edit.r_copypopup_caption",
            "height": "auto",
            "width": "700px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_GeschaeftspartnerEditUtils.doCopyCommand",
                    "bindToFQ": "functions.$f_GeschaeftspartnerEditUtils.doCopyCommand"
                }
            }]
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "geschaeftspartner-edit.r_geschaeftspartnercopynummer_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerCopy",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_GeschaeftspartnerCopy.Nummer"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerCopyNummer",
            "options": {
                "optionsName": "r_geschaeftspartnerCopyNummerOptions",
                "optionsNameFQ": "r_geschaeftspartnerCopyNummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "geschaeftspartnerart",
            "filters": [],
            "caption": "geschaeftspartner-edit.r_geschaeftspartnercopyart_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerCopy",
                "bindTo": "IdGeschaeftspartnerart",
                "bindToFQ": "models.data.$m_GeschaeftspartnerCopy.IdGeschaeftspartnerart"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerCopyArt",
            "options": {
                "optionsName": "r_geschaeftspartnerCopyArtOptions",
                "optionsNameFQ": "r_geschaeftspartnerCopyArtOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "geschaeftspartner-edit.r_geschaeftspartnercopynotiz_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerCopy",
                "bindTo": "DoKopierenNotizen",
                "bindToFQ": "models.data.$m_GeschaeftspartnerCopy.DoKopierenNotizen"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerCopyNotiz",
            "options": {
                "optionsName": "r_geschaeftspartnerCopyNotizOptions",
                "optionsNameFQ": "r_geschaeftspartnerCopyNotizOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "geschaeftspartner-edit.r_geschaeftspartnercopykonditionen_caption",
            "binding": {
                "dataContext": "$m_GeschaeftspartnerCopy",
                "bindTo": "DoKopierenKonditionen",
                "bindToFQ": "models.data.$m_GeschaeftspartnerCopy.DoKopierenKonditionen"
            },
            "validationRules": [],
            "id": "r_geschaeftspartnerCopyKonditionen",
            "options": {
                "optionsName": "r_geschaeftspartnerCopyKonditionenOptions",
                "optionsNameFQ": "r_geschaeftspartnerCopyKonditionenOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_geschaeftspartnerartPopup",
            "options": {
                "optionsName": "r_geschaeftspartnerartPopupOptions",
                "optionsNameFQ": "r_geschaeftspartnerartPopupOptions"
            },
            "caption": "geschaeftspartner-edit.r_geschaeftspartnerartpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_GeschaeftspartnerEditUtils.doGeschaeftspartnerartAendernCommand",
                    "bindToFQ": "functions.$f_GeschaeftspartnerEditUtils.doGeschaeftspartnerartAendernCommand"
                }
            }]
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "geschaeftspartnerart",
            "filters": [{
                "webApiCustomKey": "NummerGeschaeftspartner",
                "webApiCustomValue": "models.data.$m_Geschaeftspartner.Nummer"
            }],
            "caption": "geschaeftspartner-edit.r_geschaeftspartnerartedit_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_geschaeftspartnerartEdit",
            "options": {
                "optionsName": "r_geschaeftspartnerartEditOptions",
                "optionsNameFQ": "r_geschaeftspartnerartEditOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
    r_tabBewegungSelected = 0;
}