import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    ArtikelEditUtils
} from "./artikel-edit-utils";

@fwx.autoinject
export class ArtikelEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_ArtikelEditUtils: ArtikelEditUtils) {
        super(element, formBaseImport);
        this.id = "artikel-edit";
        this.title = "artikel-edit.artikel-edit_caption";
        this.addModel({
            "id": "$m_Artikel",
            "webApiAction": "ERP/Stammdaten/Artikel",
            "webApiExpand": {
                'Artikelkategorien': null
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "custom": {
                'copyFunc': '$f_ArtikelEditUtils.copyArtikel'
            },
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelLieferantRel",
            "webApiAction": "ERP/Stammdaten/ArtikelLieferant",
            "webApiExpand": {
                'Lieferant': {
                    'expand': {
                        'Adresse': null
                    }
                }
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelLieferantEdit",
            "webApiAction": "ERP/Stammdaten/ArtikelLieferant",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelKundeEdit",
            "webApiAction": "ERP/Stammdaten/ArtikelKunde",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelKundeRel",
            "webApiAction": "ERP/Stammdaten/ArtikelKunde",
            "webApiExpand": {
                'Kunde': {
                    'expand': {
                        'Adresse': null
                    }
                }
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelEinheitEdit",
            "webApiAction": "ERP/Stammdaten/ArtikelEinheit",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelEinheitRel",
            "webApiAction": "ERP/Stammdaten/ArtikelEinheit",
            "webApiExpand": {
                'Einheit': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelMindestbestandRel",
            "webApiAction": "ERP/Stammdaten/ArtikelMindestbestand",
            "webApiExpand": {
                'Filiale': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelMindestbestandEdit",
            "webApiAction": "ERP/Stammdaten/ArtikelMindestbestand",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelStuecklisteRel",
            "webApiAction": "ERP/Stammdaten/ArtikelStueckliste",
            "webApiExpand": {
                'ArtikelRW': null,
                'Einheit': {
                    'expand': {
                        'Einheit': null
                    }
                }
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelStuecklisteEdit",
            "webApiAction": "ERP/Stammdaten/ArtikelStueckliste",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelCopy",
            "filters": []
        });
        this.addModel({
            "id": "$m_EinstandspreisRel",
            "webApiAction": "ERP/Kalkulation/Einstandspreis",
            "webApiExpand": {
                'Firma': {
                    'expand': {
                        'Waehrung': null
                    }
                },
                'Artikel': {
                    'expand': {
                        'Einheit': null
                    }
                },
                'VerknuepfteEntitaet': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_EinstandspreisEdit",
            "filters": []
        });
        this.addModel({
            "id": "$m_ArtikelEdit",
            "webApiAction": "ERP/Stammdaten/Artikel",
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelEditUtils.artikelBestellungErstellenCommand",
                "bindToFQ": "functions.$f_ArtikelEditUtils.artikelBestellungErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelEditUtils.artikelProdAuftragErstellenCommand",
                "bindToFQ": "functions.$f_ArtikelEditUtils.artikelProdAuftragErstellenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelEditUtils.doExport",
                "bindToFQ": "functions.$f_ArtikelEditUtils.doExport"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelEditUtils.doBestellvorschlagBerechnenCommand",
                "bindToFQ": "functions.$f_ArtikelEditUtils.doBestellvorschlagBerechnenCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelEditUtils.showArtikelartAendernCommand",
                "bindToFQ": "functions.$f_ArtikelEditUtils.showArtikelartAendernCommand"
            }
        });
        this.addCommand({
            "binding": {
                "bindTo": "$f_ArtikelEditUtils.calcLieferterminCommand",
                "bindToFQ": "functions.$f_ArtikelEditUtils.calcLieferterminCommand"
            }
        });
        this.addEditPopup({
            "idContent": "artikelLieferantEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ArtikelLieferantEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ArtikelLieferantEdit.Id"
                }
            }],
            "id": "artikelLieferantEditPopup",
            "options": {
                "optionsName": "artikelLieferantEditPopupOptions",
                "optionsNameFQ": "artikelLieferantEditPopupOptions"
            },
            "caption": "artikel-edit.artikellieferanteditpopup_caption",
            "height": "auto",
            "width": "1150px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "artikelKundeEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ArtikelKundeEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ArtikelKundeEdit.Id"
                }
            }],
            "id": "artikelKundeEditPopup",
            "options": {
                "optionsName": "artikelKundeEditPopupOptions",
                "optionsNameFQ": "artikelKundeEditPopupOptions"
            },
            "caption": "artikel-edit.artikelkundeeditpopup_caption",
            "height": "auto",
            "width": "1150px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "artikelEinheitEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ArtikelEinheitEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ArtikelEinheitEdit.Id"
                }
            }],
            "id": "artikelEinheitEditPopup",
            "options": {
                "optionsName": "artikelEinheitEditPopupOptions",
                "optionsNameFQ": "artikelEinheitEditPopupOptions"
            },
            "caption": "artikel-edit.artikeleinheiteditpopup_caption",
            "height": "500px",
            "width": "1150px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "artikelMindestbestandEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ArtikelMindestbestandEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ArtikelMindestbestandEdit.Id"
                }
            }],
            "id": "artikelMindestbestandEditPopup",
            "options": {
                "optionsName": "artikelMindestbestandEditPopupOptions",
                "optionsNameFQ": "artikelMindestbestandEditPopupOptions"
            },
            "caption": "artikel-edit.artikelmindestbestandeditpopup_caption",
            "height": "400px",
            "width": "700px",
            "commands": []
        });
        this.addEditPopup({
            "idContent": "artikelStuecklisteEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_ArtikelStuecklisteEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_ArtikelStuecklisteEdit.Id"
                }
            }],
            "id": "artikelStuecklisteEditPopup",
            "options": {
                "optionsName": "artikelStuecklisteEditPopupOptions",
                "optionsNameFQ": "artikelStuecklisteEditPopupOptions"
            },
            "caption": "artikel-edit.artikelstuecklisteeditpopup_caption",
            "height": "400px",
            "width": "700px",
            "commands": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_ArtikelEditUtils", $f_ArtikelEditUtils, "functions.$f_ArtikelEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 25,
            "caption": "artikel-edit.r_nummer_caption",
            "isReadOnlyExpression": "models.data.$m_Artikel.Id > 0",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_Artikel.Nummer"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 25
                    }]
                }
            }],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelart",
            "filters": [],
            "caption": "artikel-edit.r_artikelart_caption",
            "isReadOnlyExpression": "models.data.$m_Artikel.Id",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "IdArtikelart",
                "bindToFQ": "models.data.$m_Artikel.IdArtikelart"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_ArtikelEditUtils.onArtikelartChanged",
            "id": "r_artikelart",
            "options": {
                "optionsName": "r_artikelartOptions",
                "optionsNameFQ": "r_artikelartOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "steuercode",
            "filter": ["ShowInArtikel", true],
            "filters": [],
            "caption": "artikel-edit.r_steuercode_caption",
            "isReadOnlyExpression": "models.data.$m_Artikel.Id > 0",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "IdSteuercode",
                "bindToFQ": "models.data.$m_Artikel.IdSteuercode"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_steuercode",
            "options": {
                "optionsName": "r_steuercodeOptions",
                "optionsNameFQ": "r_steuercodeOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "artikel-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Artikel.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "100px",
            "maxLength": 500,
            "caption": "artikel-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_Artikel.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 500
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addTab(this, {
            "pages": [{
                "id": "r_tabEinstellungen",
                "caption": "artikel-edit.r_tabeinstellungen_caption"
            }, {
                "id": "r_tabEinheiten",
                "caption": "artikel-edit.r_tabeinheiten_caption"
            }, {
                "id": "r_tabKonditionen",
                "caption": "artikel-edit.r_tabkonditionen_caption"
            }, {
                "id": "r_tabLager",
                "caption": "artikel-edit.r_tablager_caption"
            }, {
                "id": "r_tabMindestbestand",
                "caption": "artikel-edit.r_tabmindestbestand_caption"
            }, {
                "id": "r_tabStueckliste",
                "caption": "artikel-edit.r_tabstueckliste_caption"
            }, {
                "id": "r_tabKalkulation",
                "caption": "artikel-edit.r_tabkalkulation_caption"
            }, {
                "id": "r_sonstigeDaten",
                "caption": "artikel-edit.r_sonstigedaten_caption"
            }, {
                "id": "r_tabDashboard",
                "caption": "artikel-edit.r_tabdashboard_caption",
                "if": "models.data.$m_Artikel.Id"
            }],
            "id": "r_tab",
            "options": {
                "optionsName": "r_tabOptions",
                "optionsNameFQ": "r_tabOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelBezugTyp",
            "filters": [],
            "caption": "artikel-edit.r_bezugtyp_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "BezugTyp",
                "bindToFQ": "models.data.$m_Artikel.BezugTyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bezugTyp",
            "options": {
                "optionsName": "r_bezugTypOptions",
                "optionsNameFQ": "r_bezugTypOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "bestellvorschlagTyp",
            "filters": [],
            "caption": "artikel-edit.r_bestellvorschlagtyp_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "BestellvorschlagTyp",
                "bindToFQ": "models.data.$m_Artikel.BestellvorschlagTyp"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_bestellvorschlagTyp",
            "options": {
                "optionsName": "r_bestellvorschlagTypOptions",
                "optionsNameFQ": "r_bestellvorschlagTypOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 10,
            "caption": "artikel-edit.r_eindeckperiode_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Eindeckperiode",
                "bindToFQ": "models.data.$m_Artikel.Eindeckperiode"
            },
            "validationRules": [{
                "item": {
                    "type": "duration",
                    "parameters": [{
                        "name": "durationPeriodType",
                        "value": "2"
                    }]
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 10
                    }]
                }
            }],
            "id": "r_eindeckPeriode",
            "options": {
                "optionsName": "r_eindeckPeriodeOptions",
                "optionsNameFQ": "r_eindeckPeriodeOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-edit.r_bezeichnungeditierbar_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "IsBezeichnungEditierbar",
                "bindToFQ": "models.data.$m_Artikel.IsBezeichnungEditierbar"
            },
            "validationRules": [],
            "id": "r_bezeichnungEditierbar",
            "options": {
                "optionsName": "r_bezeichnungEditierbarOptions",
                "optionsNameFQ": "r_bezeichnungEditierbarOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-edit.r_lagerfuehrend_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "IsLagerfuehrend",
                "bindToFQ": "models.data.$m_Artikel.IsLagerfuehrend"
            },
            "validationRules": [],
            "id": "r_lagerfuehrend",
            "options": {
                "optionsName": "r_lagerfuehrendOptions",
                "optionsNameFQ": "r_lagerfuehrendOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-edit.r_mhd_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "SupportsMHD",
                "bindToFQ": "models.data.$m_Artikel.SupportsMHD"
            },
            "validationRules": [],
            "id": "r_mhd",
            "options": {
                "optionsName": "r_mhdOptions",
                "optionsNameFQ": "r_mhdOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-edit.r_charge_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "SupportsCharge",
                "bindToFQ": "models.data.$m_Artikel.SupportsCharge"
            },
            "validationRules": [],
            "id": "r_charge",
            "options": {
                "optionsName": "r_chargeOptions",
                "optionsNameFQ": "r_chargeOptions"
            }
        });
        this.widgetCreator.addFileUploaderWithViewer(this, {
            "acceptTypeEnum": 0,
            "acceptType": "image/*",
            "height": "230px",
            "maxFileSizeMB": 3,
            "showClearButton": true,
            "caption": "artikel-edit.r_bild_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Bild",
                "bindToFQ": "models.data.$m_Artikel.Bild"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_bild",
            "options": {
                "optionsName": "r_bildOptions",
                "optionsNameFQ": "r_bildOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_einhEinheit",
                "caption": "artikel-edit.r_einheinheit_caption",
                "bindTo": "Einheit.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_einhUmrechner",
                "caption": "artikel-edit.r_einhumrechner_caption",
                "bindTo": "Umrechner",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_einhEAN",
                "caption": "artikel-edit.r_einhean_caption",
                "bindTo": "EAN",
                "width": "120px"
            }, {
                "id": "r_einhIsVerkaufseinheit",
                "caption": "artikel-edit.r_einhisverkaufseinheit_caption",
                "bindTo": "IsVerkaufseinheit",
                "width": "120px"
            }, {
                "id": "r_einhGewichtNetto",
                "caption": "artikel-edit.r_einhgewichtnetto_caption",
                "bindTo": "GewichtNetto",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_einhGewichtBrutto",
                "caption": "artikel-edit.r_einhgewichtbrutto_caption",
                "bindTo": "GewichtBrutto",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_einhLaenge",
                "caption": "artikel-edit.r_einhlaenge_caption",
                "bindTo": "Laenge",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_einhBreite",
                "caption": "artikel-edit.r_einhbreite_caption",
                "bindTo": "Breite",
                "width": "80px",
                "format": "n0"
            }, {
                "id": "r_einhHoehe",
                "caption": "artikel-edit.r_einhhoehe_caption",
                "bindTo": "Hoehe",
                "width": "80px",
                "format": "n0"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_einheitGridToolbarOptions",
                "optionsNameFQ": "r_einheitGridToolbarOptions"
            },
            "caption": "artikel-edit.r_einheitgrid_caption",
            "binding": {
                "dataContext": "$m_Artikel"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ArtikelEinheitRel",
                "bindTo": "IdArtikel",
                "bindToFQ": "models.data.$m_ArtikelEinheitRel.IdArtikel"
            },
            "dataModel": "$m_Artikel",
            "editDataContext": "$m_ArtikelEinheitEdit",
            "idEditPopup": "artikelEinheitEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_einheitGrid",
            "options": {
                "optionsName": "r_einheitGridOptions",
                "optionsNameFQ": "r_einheitGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_liefGpNummerLieferant",
                "caption": "artikel-edit.r_liefgpnummerlieferant_caption",
                "bindTo": "Lieferant.Nummer",
                "width": "80px"
            }, {
                "id": "r_liefFirmenbez1",
                "caption": "artikel-edit.r_lieffirmenbez1_caption",
                "bindTo": "Lieferant.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_liefOrt",
                "caption": "artikel-edit.r_liefort_caption",
                "bindTo": "Lieferant.Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_liefEAN",
                "caption": "artikel-edit.r_liefean_caption",
                "bindTo": "EAN",
                "width": "120px"
            }, {
                "id": "r_liefLieferantArtikelNummer",
                "caption": "artikel-edit.r_lieflieferantartikelnummer_caption",
                "bindTo": "LieferantArtikelNr",
                "width": "80px"
            }, {
                "id": "r_liefArtikelbezeichnung",
                "caption": "artikel-edit.r_liefartikelbezeichnung_caption",
                "bindTo": "LieferantArtikelbezeichnung",
                "minWidth": 100
            }, {
                "id": "r_liefHauptlieferant",
                "caption": "artikel-edit.r_liefhauptlieferant_caption",
                "bindTo": "IsHauptlieferant",
                "width": "80px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_lieferantGridToolbarOptions",
                "optionsNameFQ": "r_lieferantGridToolbarOptions"
            },
            "caption": "artikel-edit.r_lieferantgrid_caption",
            "binding": {
                "dataContext": "$m_Artikel"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ArtikelLieferantRel",
                "bindTo": "IdArtikel",
                "bindToFQ": "models.data.$m_ArtikelLieferantRel.IdArtikel"
            },
            "dataModel": "$m_Artikel",
            "editDataContext": "$m_ArtikelLieferantEdit",
            "idEditPopup": "artikelLieferantEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_lieferantGrid",
            "options": {
                "optionsName": "r_lieferantGridOptions",
                "optionsNameFQ": "r_lieferantGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_kunGpNummerKunde",
                "caption": "artikel-edit.r_kungpnummerkunde_caption",
                "bindTo": "Kunde.Nummer",
                "width": "80px"
            }, {
                "id": "r_kunFirmenbez1",
                "caption": "artikel-edit.r_kunfirmenbez1_caption",
                "bindTo": "Kunde.Adresse.Firmenbez1",
                "minWidth": 100
            }, {
                "id": "r_kunOrt",
                "caption": "artikel-edit.r_kunort_caption",
                "bindTo": "Kunde.Adresse.Ort",
                "width": "120px"
            }, {
                "id": "r_kunKundeArtikelNummer",
                "caption": "artikel-edit.r_kunkundeartikelnummer_caption",
                "bindTo": "KundeArtikelNr",
                "width": "80px"
            }, {
                "id": "r_kunArtikelbezeichnung",
                "caption": "artikel-edit.r_kunartikelbezeichnung_caption",
                "bindTo": "KundeArtikelbezeichnung",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_kundeGridToolbarOptions",
                "optionsNameFQ": "r_kundeGridToolbarOptions"
            },
            "caption": "artikel-edit.r_kundegrid_caption",
            "binding": {
                "dataContext": "$m_Artikel"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ArtikelKundeRel",
                "bindTo": "IdArtikel",
                "bindToFQ": "models.data.$m_ArtikelKundeRel.IdArtikel"
            },
            "dataModel": "$m_Artikel",
            "editDataContext": "$m_ArtikelKundeEdit",
            "idEditPopup": "artikelKundeEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_kundeGrid",
            "options": {
                "optionsName": "r_kundeGridOptions",
                "optionsNameFQ": "r_kundeGridOptions"
            }
        });
        this.addNestedForm("konditionListNested", [{
            "to": "$v_idArtikel",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Artikel.Id",
                "propertyPrefix": null
            }
        }]);
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_einpVerkEntBezeichnung",
                "caption": "artikel-edit.r_einpverkentbezeichnung_caption",
                "minWidth": 100,
                "onCellPrepared": "functions.$f_ArtikelEditUtils.onEinstandspreisVerknEntitaetCellPrepared"
            }, {
                "id": "r_einpFirma",
                "caption": "artikel-edit.r_einpfirma_caption",
                "bindTo": "Firma.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_einpDatum",
                "caption": "artikel-edit.r_einpdatum_caption",
                "bindTo": "Datum",
                "width": "120px",
                "format": "g"
            }, {
                "id": "r_einpBestandAlt",
                "caption": "artikel-edit.r_einpbestandalt_caption",
                "bindTo": "BestandAlt",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einpZugang",
                "caption": "artikel-edit.r_einpzugang_caption",
                "bindTo": "Zugang",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_einpEinheit",
                "caption": "artikel-edit.r_einpeinheit_caption",
                "bindTo": "Artikel.Einheit.Bezeichnung",
                "width": "120px"
            }, {
                "id": "r_einpBerechnEinstandspreis",
                "caption": "artikel-edit.r_einpberechneinstandspreis_caption",
                "bindTo": "BerechnEinstandspreis",
                "width": "120px",
                "format": "n8"
            }, {
                "id": "r_einpDurchschnEinstandspreis",
                "caption": "artikel-edit.r_einpdurchschneinstandspreis_caption",
                "bindTo": "DurchschnEinstandspreis",
                "width": "120px",
                "format": "n8"
            }, {
                "id": "r_einpWaehrung",
                "caption": "artikel-edit.r_einpwaehrung_caption",
                "bindTo": "Firma.Waehrung.ISO4217",
                "width": "70px"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_einstandspreisGridToolbarOptions",
                "optionsNameFQ": "r_einstandspreisGridToolbarOptions"
            },
            "caption": "artikel-edit.r_einstandspreisgrid_caption",
            "binding": {
                "dataContext": "$m_Artikel"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_EinstandspreisRel",
                "bindTo": "IdArtikel",
                "bindToFQ": "models.data.$m_EinstandspreisRel.IdArtikel"
            },
            "dataModel": "$m_Artikel",
            "editDataContext": "$m_EinstandspreisEdit",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_einstandspreisGrid",
            "options": {
                "optionsName": "r_einstandspreisGridOptions",
                "optionsNameFQ": "r_einstandspreisGridOptions"
            }
        });
        this.addNestedForm("lagerbestandListNested", [{
            "to": "$v_idArtikel",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Artikel.Id",
                "propertyPrefix": null
            }
        }]);
        this.addNestedForm("lagerbestandDetailListNested", [{
            "to": "$v_idArtikel",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Artikel.Id",
                "propertyPrefix": null
            }
        }]);
        this.addNestedForm("lagerbuchungInfoListNested", [{
            "to": "$v_idArtikel",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Artikel.Id",
                "propertyPrefix": null
            }
        }]);
        this.addNestedForm("lagerbuchungListNested", [{
            "to": "$v_idArtikel",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "Id",
                "bindToFQ": "models.data.$m_Artikel.Id",
                "propertyPrefix": null
            }
        }]);
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_mbFiliale",
                "caption": "artikel-edit.r_mbfiliale_caption",
                "bindTo": "Filiale.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_mbMindestbestand",
                "caption": "artikel-edit.r_mbmindestbestand_caption",
                "bindTo": "Mindestbestand",
                "width": "120px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_mindestbestandGridToolbarOptions",
                "optionsNameFQ": "r_mindestbestandGridToolbarOptions"
            },
            "caption": "artikel-edit.r_mindestbestandgrid_caption",
            "binding": {
                "dataContext": "$m_Artikel"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ArtikelMindestbestandRel",
                "bindTo": "IdArtikel",
                "bindToFQ": "models.data.$m_ArtikelMindestbestandRel.IdArtikel"
            },
            "dataModel": "$m_Artikel",
            "editDataContext": "$m_ArtikelMindestbestandEdit",
            "idEditPopup": "artikelMindestbestandEditPopup",
            "height": "150px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_mindestbestandGrid",
            "options": {
                "optionsName": "r_mindestbestandGridOptions",
                "optionsNameFQ": "r_mindestbestandGridOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_stklArtikelNr",
                "caption": "artikel-edit.r_stklartikelnr_caption",
                "bindTo": "ArtikelRW.Nummer",
                "width": "80px"
            }, {
                "id": "r_stklArtikelbez",
                "caption": "artikel-edit.r_stklartikelbez_caption",
                "bindTo": "ArtikelRW.Bezeichnung",
                "minWidth": 100
            }, {
                "id": "r_stklMenge",
                "caption": "artikel-edit.r_stklmenge_caption",
                "bindTo": "MengeVerpEH",
                "width": "80px",
                "format": "n2"
            }, {
                "id": "r_stklEinheit",
                "caption": "artikel-edit.r_stkleinheit_caption",
                "bindTo": "Einheit.Einheit.Bezeichnung",
                "width": "100px"
            }, {
                "id": "r_stklFaktor",
                "caption": "artikel-edit.r_stklfaktor_caption",
                "bindTo": "Faktor",
                "width": "150px",
                "enumTypeName": "TIP.ERP.Business.Enumerations.StuecklisteFaktorTyp"
            }, {
                "id": "r_stklIsBeistellartikel",
                "caption": "artikel-edit.r_stklisbeistellartikel_caption",
                "bindTo": "IsBeistellartikel",
                "width": "100px"
            }, {
                "id": "r_stklBestandRW",
                "caption": "artikel-edit.r_stklbestandrw_caption",
                "bindTo": "BestandRW",
                "width": "80px",
                "format": "n2"
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_stuecklisteGridToolbarOptions",
                "optionsNameFQ": "r_stuecklisteGridToolbarOptions"
            },
            "caption": "artikel-edit.r_stuecklistegrid_caption",
            "binding": {
                "dataContext": "$m_Artikel"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_ArtikelStuecklisteRel",
                "bindTo": "IdArtikelFW",
                "bindToFQ": "models.data.$m_ArtikelStuecklisteRel.IdArtikelFW"
            },
            "dataModel": "$m_Artikel",
            "editDataContext": "$m_ArtikelStuecklisteEdit",
            "idEditPopup": "artikelStuecklisteEditPopup",
            "height": "300px",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_stuecklisteGrid",
            "options": {
                "optionsName": "r_stuecklisteGridOptions",
                "optionsNameFQ": "r_stuecklisteGridOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-edit.r_iswebshop_caption",
            "binding": {
                "dataContext": "$m_Artikel",
                "bindTo": "IsWebshop",
                "bindToFQ": "models.data.$m_Artikel.IsWebshop"
            },
            "validationRules": [],
            "id": "r_isWebshop",
            "options": {
                "optionsName": "r_isWebshopOptions",
                "optionsNameFQ": "r_isWebshopOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_copyPopup",
            "options": {
                "optionsName": "r_copyPopupOptions",
                "optionsNameFQ": "r_copyPopupOptions"
            },
            "caption": "artikel-edit.r_copypopup_caption",
            "height": "auto",
            "width": "700px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_ArtikelEditUtils.doCopyCommand",
                    "bindToFQ": "functions.$f_ArtikelEditUtils.doCopyCommand"
                }
            }]
        });
        this.widgetCreator.addTextBox(this, {
            "caption": "artikel-edit.r_artikelcopynummer_caption",
            "binding": {
                "dataContext": "$m_ArtikelCopy",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_ArtikelCopy.Nummer"
            },
            "validationRules": [],
            "id": "r_artikelCopyNummer",
            "options": {
                "optionsName": "r_artikelCopyNummerOptions",
                "optionsNameFQ": "r_artikelCopyNummerOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelart",
            "filters": [],
            "caption": "artikel-edit.r_artikelcopyart_caption",
            "binding": {
                "dataContext": "$m_ArtikelCopy",
                "bindTo": "IdArtikelart",
                "bindToFQ": "models.data.$m_ArtikelCopy.IdArtikelart"
            },
            "validationRules": [],
            "id": "r_artikelCopyArt",
            "options": {
                "optionsName": "r_artikelCopyArtOptions",
                "optionsNameFQ": "r_artikelCopyArtOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-edit.r_artikelcopynotiz_caption",
            "binding": {
                "dataContext": "$m_ArtikelCopy",
                "bindTo": "DoKopierenNotizen",
                "bindToFQ": "models.data.$m_ArtikelCopy.DoKopierenNotizen"
            },
            "validationRules": [],
            "id": "r_artikelCopyNotiz",
            "options": {
                "optionsName": "r_artikelCopyNotizOptions",
                "optionsNameFQ": "r_artikelCopyNotizOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "artikel-edit.r_artikelcopykonditionen_caption",
            "binding": {
                "dataContext": "$m_ArtikelCopy",
                "bindTo": "DoKopierenKonditionen",
                "bindToFQ": "models.data.$m_ArtikelCopy.DoKopierenKonditionen"
            },
            "validationRules": [],
            "id": "r_artikelCopyKonditionen",
            "options": {
                "optionsName": "r_artikelCopyKonditionenOptions",
                "optionsNameFQ": "r_artikelCopyKonditionenOptions"
            }
        });
        this.widgetCreator.addPopup(this, {
            "id": "r_artikelArtPopup",
            "options": {
                "optionsName": "r_artikelArtPopupOptions",
                "optionsNameFQ": "r_artikelArtPopupOptions"
            },
            "caption": "artikel-edit.r_artikelartpopup_caption",
            "height": "auto",
            "width": "500px",
            "commands": [{
                "binding": {
                    "bindTo": "$f_ArtikelEditUtils.doArtikelartAendernCommand",
                    "bindToFQ": "functions.$f_ArtikelEditUtils.doArtikelartAendernCommand"
                }
            }]
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "artikelart",
            "filters": [],
            "caption": "artikel-edit.r_artikelartedit_caption",
            "binding": {},
            "validationRules": [],
            "id": "r_artikelartEdit",
            "options": {
                "optionsName": "r_artikelartEditOptions",
                "optionsNameFQ": "r_artikelartEditOptions"
            }
        });
        super.onConstructionFinished();
    }
    r_tabSelected = 0;
}