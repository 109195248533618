import * as fwx from "../../../../framework/forms/form-export";
import {
    FibuKontoEditUtils
} from "./fibu-konto-edit-utils";

@fwx.autoinject
export class FibuKontoEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_FibuKontoEditUtils: FibuKontoEditUtils) {
        super(element, formBaseImport);
        this.id = "fibu-konto-edit";
        this.title = "fibu-konto-edit.fibu-konto-edit_caption";
        this.addModel({
            "id": "$m_FibuKonto",
            "webApiAction": "ERP/Fibu/Konto",
            "webApiExpand": {
                'Firma': null,
                'Kontogruppe': null
            },
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addModel({
            "id": "$m_FibuKontoFilterEdit",
            "webApiAction": "ERP/Fibu/KontoFilter",
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addModel({
            "id": "$m_FibuKontoFilterRel",
            "webApiAction": "ERP/Fibu/KontoFilter",
            "webApiExpand": {
                'Steuersatz': null
            },
            "optimisticLockingEnabled": true,
            "keyProperty": "Id",
            "filters": []
        });
        this.addEditPopup({
            "idContent": "fibuKontoFilterEditPopupContent",
            "mappings": [{
                "to": "$id",
                "binding": {
                    "dataContext": "$m_FibuKontoFilterEdit",
                    "bindTo": "Id",
                    "bindToFQ": "models.data.$m_FibuKontoFilterEdit.Id"
                }
            }],
            "id": "fibuKontoFilterEditPopup",
            "options": {
                "optionsName": "fibuKontoFilterEditPopupOptions",
                "optionsNameFQ": "fibuKontoFilterEditPopupOptions"
            },
            "caption": "fibu-konto-edit.fibukontofiltereditpopup_caption",
            "height": "400px",
            "width": "700px",
            "commands": []
        });
        this.addFunction("$f_FibuKontoEditUtils", $f_FibuKontoEditUtils, "functions.$f_FibuKontoEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "fibu-konto-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_FibuKonto.Id > 0",
            "binding": {
                "dataContext": "$m_FibuKonto",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_FibuKonto.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "fibuKontogruppe",
            "filters": [],
            "caption": "fibu-konto-edit.r_kontogruppe_caption",
            "binding": {
                "dataContext": "$m_FibuKonto",
                "bindTo": "IdKontogruppe",
                "bindToFQ": "models.data.$m_FibuKonto.IdKontogruppe"
            },
            "validationRules": [],
            "id": "r_kontogruppe",
            "options": {
                "optionsName": "r_kontogruppeOptions",
                "optionsNameFQ": "r_kontogruppeOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "f0",
            "caption": "fibu-konto-edit.r_nummer_caption",
            "binding": {
                "dataContext": "$m_FibuKonto",
                "bindTo": "Nummer",
                "bindToFQ": "models.data.$m_FibuKonto.Nummer"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_nummer",
            "options": {
                "optionsName": "r_nummerOptions",
                "optionsNameFQ": "r_nummerOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "fibu-konto-edit.r_bezeichnung_caption",
            "binding": {
                "dataContext": "$m_FibuKonto",
                "bindTo": "Bezeichnung",
                "bindToFQ": "models.data.$m_FibuKonto.Bezeichnung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_bezeichnung",
            "options": {
                "optionsName": "r_bezeichnungOptions",
                "optionsNameFQ": "r_bezeichnungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "caption": "fibu-konto-edit.r_sortnr_caption",
            "binding": {
                "dataContext": "$m_FibuKonto",
                "bindTo": "SortNr",
                "bindToFQ": "models.data.$m_FibuKonto.SortNr"
            },
            "validationRules": [],
            "id": "r_sortNr",
            "options": {
                "optionsName": "r_sortNrOptions",
                "optionsNameFQ": "r_sortNrOptions"
            }
        });
        this.widgetCreator.addCheckBox(this, {
            "caption": "fibu-konto-edit.r_iskassa_caption",
            "binding": {
                "dataContext": "$m_FibuKonto",
                "bindTo": "IsKassa",
                "bindToFQ": "models.data.$m_FibuKonto.IsKassa"
            },
            "validationRules": [],
            "id": "r_isKassa",
            "options": {
                "optionsName": "r_isKassaOptions",
                "optionsNameFQ": "r_isKassaOptions"
            }
        });
        this.widgetCreator.addDataGrid(this, {
            "columns": [{
                "id": "r_fibuKFSteuerTyp",
                "caption": "fibu-konto-edit.r_fibukfsteuertyp_caption",
                "bindTo": "SteuerTyp",
                "minWidth": 100,
                "enumTypeName": "TIP.ERP.Business.Enumerations.SteuerTyp"
            }, {
                "id": "r_fibuKFSteuersatz",
                "caption": "fibu-konto-edit.r_fibukfsteuersatz_caption",
                "bindTo": "Steuersatz.Bezeichnung",
                "minWidth": 100
            }],
            "createToolbar": true,
            "optionsToolbar": {
                "optionsName": "r_fibuKontoFilterGridToolbarOptions",
                "optionsNameFQ": "r_fibuKontoFilterGridToolbarOptions"
            },
            "caption": "fibu-konto-edit.r_fibukontofiltergrid_caption",
            "binding": {
                "dataContext": "$m_FibuKonto"
            },
            "isRelation": true,
            "relationBinding": {
                "dataContext": "$m_FibuKontoFilterRel",
                "bindTo": "IdFibuKonto",
                "bindToFQ": "models.data.$m_FibuKontoFilterRel.IdFibuKonto"
            },
            "dataModel": "$m_FibuKonto",
            "editDataContext": "$m_FibuKontoFilterEdit",
            "idEditPopup": "fibuKontoFilterEditPopup",
            "listEdits": [],
            "filters": [],
            "commands": [],
            "id": "r_fibuKontoFilterGrid",
            "options": {
                "optionsName": "r_fibuKontoFilterGridOptions",
                "optionsNameFQ": "r_fibuKontoFilterGridOptions"
            }
        });
        super.onConstructionFinished();
    }
}