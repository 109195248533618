import * as fwx from "../../../../framework/forms/form-export";
import {
    StatusService
} from "../../../services/status-service";
import {
    GutscheinEditUtils
} from "./gutschein-edit-utils";

@fwx.autoinject
export class GutscheinEditForm extends fwx.FormBase {
    constructor(
        element: Element,
        formBaseImport: fwx.FormBaseImport,
        private $f_StatusService: StatusService,
        private $f_GutscheinEditUtils: GutscheinEditUtils) {
        super(element, formBaseImport);
        this.id = "gutschein-edit";
        this.title = "gutschein-edit.gutschein-edit_caption";
        this.addModel({
            "id": "$m_Gutschein",
            "webApiAction": "ERP/Verkauf/Gutschein",
            "webApiExpand": {
                'Folgegutschein': {
                    'columns': [
                        'Id',
                        'Code'
                    ]
                }
            },
            "modificationInfoEnabled": true,
            "optimisticLockingEnabled": true,
            "key": "variables.data.$id",
            "keyProperty": "Id",
            "postOnSave": true,
            "filters": []
        });
        this.addFunction("$f_StatusService", $f_StatusService, "functions.$f_StatusService");
        this.addFunction("$f_GutscheinEditUtils", $f_GutscheinEditUtils, "functions.$f_GutscheinEditUtils");
        this.widgetCreator.addValidationGroup(this, {
            "id": "wd1",
            "options": {
                "optionsName": "wd1Options",
                "optionsNameFQ": "wd1Options"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "firma",
            "filters": [],
            "caption": "gutschein-edit.r_firma_caption",
            "isReadOnlyExpression": "models.data.$m_Gutschein.Id > 0",
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "IdFirma",
                "bindToFQ": "models.data.$m_Gutschein.IdFirma"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_GutscheinEditUtils.onFirmaChanged",
            "id": "r_firma",
            "options": {
                "optionsName": "r_firmaOptions",
                "optionsNameFQ": "r_firmaOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 40,
            "caption": "gutschein-edit.r_code_caption",
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "Code",
                "bindToFQ": "models.data.$m_Gutschein.Code"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }, {
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 40
                    }]
                }
            }],
            "id": "r_code",
            "options": {
                "optionsName": "r_codeOptions",
                "optionsNameFQ": "r_codeOptions"
            }
        });
        this.widgetCreator.addTextBox(this, {
            "maxLength": 100,
            "caption": "gutschein-edit.r_name_caption",
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "Name",
                "bindToFQ": "models.data.$m_Gutschein.Name"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 100
                    }]
                }
            }],
            "id": "r_name",
            "options": {
                "optionsName": "r_nameOptions",
                "optionsNameFQ": "r_nameOptions"
            }
        });
        this.widgetCreator.addTextArea(this, {
            "height": "150px",
            "maxLength": 500,
            "caption": "gutschein-edit.r_text_caption",
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "Text",
                "bindToFQ": "models.data.$m_Gutschein.Text"
            },
            "validationRules": [{
                "item": {
                    "type": "stringLength",
                    "parameters": [{
                        "name": "max",
                        "value": 500
                    }]
                }
            }],
            "id": "r_text",
            "options": {
                "optionsName": "r_textOptions",
                "optionsNameFQ": "r_textOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "min": 0.0,
            "format": "n2",
            "caption": "gutschein-edit.r_wertgesamt_caption",
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "WertGesamt",
                "bindToFQ": "models.data.$m_Gutschein.WertGesamt"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_wertGesamt",
            "options": {
                "optionsName": "r_wertGesamtOptions",
                "optionsNameFQ": "r_wertGesamtOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "waehrung",
            "filters": [],
            "caption": "gutschein-edit.r_waehrung_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "IdWaehrung",
                "bindToFQ": "models.data.$m_Gutschein.IdWaehrung"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "id": "r_waehrung",
            "options": {
                "optionsName": "r_waehrungOptions",
                "optionsNameFQ": "r_waehrungOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "gutschein-edit.r_wertreserviert_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "WertReserviert",
                "bindToFQ": "models.data.$m_Gutschein.WertReserviert"
            },
            "validationRules": [],
            "id": "r_wertReserviert",
            "options": {
                "optionsName": "r_wertReserviertOptions",
                "optionsNameFQ": "r_wertReserviertOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "gutschein-edit.r_werteingeloest_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "WertEingeloest",
                "bindToFQ": "models.data.$m_Gutschein.WertEingeloest"
            },
            "validationRules": [],
            "id": "r_wertEingeloest",
            "options": {
                "optionsName": "r_wertEingeloestOptions",
                "optionsNameFQ": "r_wertEingeloestOptions"
            }
        });
        this.widgetCreator.addNumberBox(this, {
            "format": "n2",
            "caption": "gutschein-edit.r_wertoffen_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "WertOffen",
                "bindToFQ": "models.data.$m_Gutschein.WertOffen"
            },
            "validationRules": [],
            "id": "r_wertOffen",
            "options": {
                "optionsName": "r_wertOffenOptions",
                "optionsNameFQ": "r_wertOffenOptions"
            }
        });
        this.widgetCreator.addSelectBox(this, {
            "idSelect": "status",
            "filters": [],
            "caption": "gutschein-edit.r_status_caption",
            "isReadOnly": true,
            "binding": {
                "dataContext": "$m_Gutschein",
                "bindTo": "IdStatus",
                "bindToFQ": "models.data.$m_Gutschein.IdStatus"
            },
            "validationRules": [{
                "item": {
                    "type": "required",
                    "parameters": []
                }
            }],
            "onValueChanged": "functions.$f_StatusService.onValueChanged",
            "id": "r_status",
            "options": {
                "optionsName": "r_statusOptions",
                "optionsNameFQ": "r_statusOptions"
            }
        });
        super.onConstructionFinished();
    }
}